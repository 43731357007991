import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Contactpage from "../../components/Contactpage/Contactpage";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import ContactForm from "../../components/ContactFrom";

const ContactPage = () => {
  return (
    <Fragment>
      <Navbar hclass={"wpo-header-style-5"} topbarClass={"tb-block"} />
      <PageTitle pageTitle={" حجز أفراد"} pagesub={"حجز أفراد"} />
      <Contactpage>
        <ContactForm />
      </Contactpage>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ContactPage;
