import React from "react";
import abimg2 from "../../images/about/MHD2-1024x1020.jpg";
import abimg from "../../images/about/mm.jpg";

const About = (props) => {
  return (
    <section
      className={`wpo-about-section-s3 section-padding ${props.abClass}`}
    >
      <div className="container">
        <div className="wpo-section-title-s2">
          <span>من نحن</span>
          <h2 style={{ textAlign: "center" }}>
            مؤسسة الجمل للمحاماة والاستشارات القانونية
          </h2>
        </div>
        <div className="wpo-about-section-wrapper">
          <div className="row align-items-center">
            {/* <div className="col-lg-5 col-md-12 col-12"> */}
            <div className="wpo-about-content">
              <h2 style={{ color: " #af914e" }}>مبادئ المؤسسة </h2>
              <div className="wpo-about-content-inner">
                <p>
                  الالتزام بالسرية التامة فيما يخص بيانات عملاء المؤسسة.
                  المبادرة إلى تقديم أفضل الاستشارات والأعمال القانونية للعميل
                  مع توجيهه إلى أصلح الخيارات. الإخلاص الدائم لعملاء المؤسسة
                  أثناء وكالتنا أو بعدها. الالتزام بفحص كل قضية وبحثها على وجه
                  الخصوص دون اتباع أسلوب النماذج القانونية المعدة سلفًا. قضايا
                  عملائنا هي أولوية عملنا بالمؤسسة. نعتبر أنفسنا درعًا لعملاء
                  المؤسسة في حماية مصالحهم المختلفة. الالتزام بالتطوير العلمي
                  والعملي المستمر لأعضاء المؤسسة ومجالات عملها. متابعة واطلاع
                  العملاء على مستجدات أعمالهم القانونية لدى المؤسسة بكل شفافية
                  ووضوح أول بأول.
                </p>
              </div>
            </div>
            <div className="wpo-about-content">
              <h2 style={{ color: " #af914e" }}>تاريخ المؤسسة </h2>
              <div className="wpo-about-content-inner">
                <p>
                  تأسست المؤسسة عام 2015 و لما كان الالتزام بالسرية التامة أهم
                  وأول مباديء مؤسسة الجمل للمحاماة، فإننا نلتزم بعدم الإفصاح
                  والتفاخر بإنجازات المؤسسة في أروقة المحاكم وفي قطاع
                  الاستثمارات الدولية وغيرها من قطاعات الأعمال المتنوعة التي
                  تتولاها المؤسسة، لأننا نؤمن بأن نجاحنا فيما نحققه لصالح
                  عملائنا من إنجازات يُعد أمرًا شديد الخصوصية ويندرج خلف مبدأ
                  الالتزام بالسرية التامة لصالح عملائنا.
                </p>
              </div>
            </div>
          </div>

          {/* <div className="wpo-about-content">
            <h2 style={{ color: "#af914e" }}>شركاء المؤسسة بالخارج</h2>
            <div className="wpo-about-content-inner">
              <p>
                مكتب الخماش للمحاماة بمكة المكرمة
                <br />
                مكتب بخيت الحويس بجدة
                <br />
                مكتب محمد بيازي بالمغرب
              </p>
            </div>
          </div> */}
          {/* <div className="col-lg-7 col-md-12 col-12">
              <div className="wpo-about-img">
                <img src={abimg} alt="" /> */}
          {/* <div className="about-img-inner">
                  <img src={abimg2} alt="" />
                </div> */}
          {/* </div>
            </div> */}
          {/* </div> */}
          {/* <div className="visible-text">
            <h2>About</h2>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default About;
