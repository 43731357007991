import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../store/pages/profileSlice";

function FileProfile() {
  const { profile, loading } = useSelector((state) => state.profile);
  const dispatch = useDispatch();


  console.log(profile)
  useEffect(() => {
    if (!profile.file) {
      dispatch(getProfile());
    }
  }, [dispatch, profile.file]);

  return (
    <div
      className="  overflow-hidden text-center frame body-bg"
      style={{backgroundColor: "#001635" }}
      role="document"
    >
      {loading || !profile.file ? (
        <div className="spinner-border text-primary my-5" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        <div  className="pdf-container"
        style={{
          backgroundColor: '#red', // Custom background color
          height: '500px',
          overflow: 'hidden',
          textAlign: 'center',
          position: 'relative',
        }}>

<object
    data={profile.file}
    type="application/pdf"
    style={{
      height: '100%',
      width: '100%',
      // pointerEvents: 'none', // Prevent interaction with the embedded object
    }}
  ></object>

        </div>
        
      )}
    </div>
  );
}

export default FileProfile;




