import React, { Fragment, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { useLocation, useParams } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import Sidebar from "./Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { getAllArticals, getSpecialService } from "../../store/pages/articalsSlice";
import CommentForm from "./CommentForm";
import { getSpecialMaterial } from "../../store/pages/materialSlice";

const MaterialSinglePage = (props) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const location = useLocation();
    const { material, loading } = useSelector((state) => state.material);
    console.log(material)
    useEffect(() => {
        dispatch(getSpecialMaterial(id));
        window.scrollTo(10, 0);
    }, [id]);
    console.log(material?.content);

    return (
        <Fragment>
            <Navbar hclass={"wpo-header-style-5"} topbarClass={"tb-block"} />
            <PageTitle pageTitle={material?.content} pagesub={"معرفة قانونية"} />
            <section
                className={`wpo-service-single-section section-padding wpo-practice-section-s2 `}
            >
                <div className="container-fluid ">
                    <div className="row">
                        <div className="col-lg-2 col-md-12 order-lg-3 order-1 mt--60">
                            <div className="rule-area">
                                <div className="wpo-service-single-sub-img ">
                                    <img src={material?.image} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 order-lg-2 order-1">
                            <div className="wpo-service-single-wrap">
                                <div className="wpo-service-single-content">
                                    <div className="wpo-service-single-content-des ">
                                        <h2>{material?.title}</h2>
                                        <p
                                            className="code-content"
                                            dangerouslySetInnerHTML={{
                                                __html: material?.description,
                                            }}
                                        ></p>
                                        <div>
                                            <object
                                                data={material?.file + "#toolbar=0&navpanes=0"}
                                                style={{ width: '100%', height: '500px' }} // Adjust height as needed
                                                type="application/pdf" // Change type according to your file type
                                            >
                                                <p>Your browser does not support PDFs. Download the PDF to view it: <a href={material?.file}>Download PDF</a>.</p>
                                            </object>
                                        </div>
                                        <div className="wpo-contact-pg-section section-padding">

                                            <div className="wpo-practice-section-s2">
                                                <div className="wpo-section-title">
                                                    <h2> اترك تعليقاً</h2>
                                                    <p>
                                                        لن يتم نشر عنوان بريدك الإلكتروني. الحقول الإلزامية مشار
                                                        إليها بـ *
                                                    </p>
                                                </div>
                                                <CommentForm />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <Sidebar />

                    </div>
                </div>
            </section>

            <Footer />
            <Scrollbar />
        </Fragment>
    );
};
export default MaterialSinglePage;
