import React, { Fragment, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { useLocation, useParams } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getAllMaterials } from "../../store/pages/materialSlice";
import styles from "./training.module.css";

const Training = (props) => {
  const { title } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { materials, loading } = useSelector((state) => state.material);
  console.log(materials);

  useEffect(() => {
    dispatch(getAllMaterials());
    window.scrollTo(10, 0);
  }, [dispatch]);

  return (
    <Fragment>
      <Navbar hclass={"wpo-header-style-5"} topbarClass={"tb-block"} />
      <PageTitle
        pageTitle={"التدريب و التأهيل"}
        pagesub={"التدريب والتأهيل "}
      />
      <div className="container"></div>
      <div className="container mt-5">
        <h3>التأهيل و التدريب</h3>
        <h5 className="mt-4">افرع التدريب المتاحة</h5>
        <div className={styles["line-bottom"]}></div>
        <div className="row">
          <p className={`${styles["branch"]} col-md-4`}>فرع الجنائي</p>
          <p className={`${styles["branch"]} col-md-4`}>فرع الاداري</p>
          <p className={`${styles["branch"]} col-md-4`}>فرع المدني </p>
          <p className={`${styles["branch"]} col-md-4`}>فرع التجاري</p>
          <p className={`${styles["branch"]} col-md-4`}>فرع الدولي</p>
          <p className={`${styles["branch"]} col-md-4`}>فرع البحث القانوني</p>
        </div>
        <h3 style={{ marginTop: "40px" }}>
          التأهيل لسوق العمل القانونية المصرية و العربية الدولية
        </h3>
        <div className={styles["line-bottom"]}></div>
        <div className="row">
          <p className={`${styles["branch"]} col-md-6`}>
            التأهيل للالتحاق بشركات المحاماة الدولية
          </p>
          <p className={`${styles["branch"]} col-md-6`}>
            التأهيل لتأسيس مكاتب و شركات المحاماة المحلية و الدولية و ادارتها
          </p>
          <p className={`${styles["branch"]} col-md-6`}>
            التأهيل للالتحاق بالسلك الدبلوماسى{" "}
          </p>
          <p className={`${styles["branch"]} col-md-6`}>
            التأهيل للالتحاق بالشئون القانونية بالبنوك المختلفة
          </p>
          <p className={`${styles["branch"]} col-md-6`}>
            التأهيل للالتحاق بالهيئات القضائية
          </p>
          <p className={`${styles["branch"]} col-md-6`}>
            التأهيل للالتحاق بشئون الشركات التجارية القانونية
          </p>
          <p className={`${styles["branch"]} col-md-6`}>
            التأهيل لاجراء البحوث القانونية المتقدمة
          </p>
        </div>
        <h3 style={{ marginTop: "40px" }}>الفعاليات الثقافية</h3>
        <div className={styles["line-bottom"]}></div>
        <p className={styles.branch}>
          تهتم المؤسسه بالجانب العلمى و الاجتماعى على السواء حيث تقوم بتنظيم
          الندوات العلمية و المؤتمرات الدورية و حفلات التكريم العلمية التشجيعية
          لأعضاء المؤسسة و للغير.{" "}
        </p>
      </div>

      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default Training;
