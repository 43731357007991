import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Attorneys from "../../api/attorneys";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllEmployees,
  getSpecialEmployee,
} from "../../store/pages/employeesSlice";
import { getAllServices } from "../../store/pages/servicesSlice";
import Navbar from "../Navbar/Navbar";

const EmployeeProfile = (props) => {
  const param = useParams();
  console.log(param);
  const { employee } = useSelector((state) => state.employees);
  const dispatch = useDispatch();
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  useEffect(() => {
    dispatch(getSpecialEmployee(param?.id));
  }, [dispatch]);
  return (
    <div
      className="wpo-team-section"
      style={{ height: "100vh", overflow: "scroll" }}
    >
      <Navbar hclass={"wpo-header-style-5"} topbarClass={"tb-block"} />
      <section className={` h-screen ${props.tmClass} `}
      >
        <div className="container" >
          <div className="row justify-content-between">
            {/* Employee Info */}
            <div className="col-12 col-md-6 slide-title">
              <p style={{ fontSize: "3rem" }}>
                {employee.first_name + " " + employee.last_name}
              </p>
              <p>{employee.job_name}</p>
              <p>{employee.birth_date}</p>
              <p>+ {employee.experience_no} سنة خبرة</p>
              <p>{employee.district}</p>
            </div>

            {/* Employee Image */}
            <div className="col-12 col-md-6 d-flex justify-content-md-end justify-content-center">
              <img
                src={employee?.image}
                style={{ width: "250px" }}
                alt="Employee Image"
              />
            </div>
          </div>

          {/* CV Section */}
          {employee.cv && (<>
            <p style={{ fontSize: "2.5rem" }}>السيرة الذاتية</p>
            <div style={{ height: "100vh", maxWidth: "55rem", margin: "0px auto" }}>
              <object width={"100%"} height={"100%"} data={employee.cv + "#toolbar=0&navpanes=0"}></object>
            </div>
          </>
          )}
        </div>
      </section>

    </div>
  );
};

export default EmployeeProfile;
