import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Contactpage from "../../components/Contactpage/Contactpage";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import ContactFormCompanies from "../../components/ContactFrom/indexCompanies";
function ContactCompanies() {
  return (
    <Fragment>
      <Navbar hclass={"wpo-header-style-5"} topbarClass={"tb-block"} />
      <PageTitle pageTitle={" حجز شركات"} pagesub={"حجز شركات"} />
      <Contactpage>
        <ContactFormCompanies />
      </Contactpage>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
}

export default ContactCompanies;
