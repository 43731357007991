import atimg1 from "../images/team/img-1.jpg";
import atimg2 from "../images/team/img-2.jpg";
import atimg3 from "../images/team/img-3.jpg";
import atimg4 from "../images/team/img-4.jpg";
import salah from "../images/team/salah.jpg";
import abdallah from "../images/team/Abdallah-1.jpg";
import khaled from "../images/team/final-4.jpg";
import female from "../images/team/female.jpg";
import abdelraof from "../images/team/final.jpg";
import badr from "../images/team/final-2.jpg";
import emad from "../images/team/New-Project-1-1.jpg";
import mohamed from "../images/team/New-Project-1.jpg";
import faisal from "../images/team/fasial.jpg";

const Attorneys = [
  {
    Id: "1",
    AtImg: salah,
    name: "محمد صلاح عودة الاحمدى",
    slug: "Robert-Fox",
    title: "المحامي والمؤسس",
  },
  {
    Id: "2",
    AtImg: abdallah,
    name: "عبدالله الشنقيطى",
    slug: "Jane-Cooper",
    title: "مـديـر فـرع الـريـاض الــمــــوثــــــق",
  },
  {
    Id: "3",
    AtImg: khaled,
    name: "خالد محمد الاحمدى",
    slug: "Jacob-Jones",
    title: "محامي متدرب",
  },
  {
    Id: "4",
    AtImg: female,
    name: "عفاف فهد المغامسي",
    slug: "Jerome-Bell",
    title: "محامية متدربة",
  },
  {
    Id: "5",
    AtImg: abdelraof,
    name: "عبدالرؤوف علواته السيد",
    slug: "Jane-Cooper",
    title: "محامي متدرب",
  },
  {
    Id: "6",
    AtImg: female,
    name: "هند بنت صالح المرعشي  ",
    slug: "Jane-Cooper",
    title: "محامية متدربة",
  },
  {
    Id: "7",
    AtImg: badr,
    name: " بدر صالح الربيقى",
    slug: "Jane-Cooper",
    title: "محامي متدرب",
  },
  {
    Id: "8",
    AtImg: emad,
    name: "  عماد المهلكى",
    slug: "Jane-Cooper",
    title: "محامي متدرب",
  },
  {
    Id: "9",
    AtImg: female,
    name: "  ناهد على محمد صالح ",
    slug: "Jane-Cooper",
    title: " فريق المؤسسة",
  },
  {
    Id: "10",
    AtImg: mohamed,
    name: "محمد عبدالله حماد",
    slug: "Jane-Cooper",
    title: " محامي متدرب",
  },
  {
    Id: "10",
    AtImg: female,
    name: "اسرار الحميدى العنزى  ",
    slug: "Jane-Cooper",
    title: "محامية مرخصة ",
  },
  {
    Id: "10",
    AtImg: faisal,
    name: " فيصل حامد الركن",
    slug: "Jane-Cooper",
    title: " Lawer",
  },
  {
    Id: "10",
    AtImg: female,
    name: "العنود سعود",
    slug: "Jane-Cooper",
    title: " فريق المؤسسة",
  },
];

export default Attorneys;
