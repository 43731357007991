import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASEURL } from "../../api/Api";

export const getAllClients = createAsyncThunk('getAllClients',
  async (data, rejectWithValue) => {
    try {

      const response = await axios.get(`${BASEURL}/website/company_clients/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  })

export const getSpecialClient = createAsyncThunk('getSpecialClint',
  async (id, rejectWithValue) => {
    try {

      const response = await axios.get(`${BASEURL}/website/company_clients/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  })

const ClientsSlice = createSlice({
  name: 'clients',
  initialState: {
    loading: false,
    error: null,
    clients: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllClients.pending, (state, action) => {
        state.loading = true;
        state.error = null
        state.clients = []
      })
      .addCase(getAllClients.fulfilled, (state, action) => {
        state.loading = false
        state.clients = action.payload

      })
      .addCase(getAllClients.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
    builder
      .addCase(getSpecialClient.pending, (state, action) => {
        state.loading = true;
        state.error = null
        state.clients = []
      })
      .addCase(getSpecialClient.fulfilled, (state, action) => {
        state.loading = false
        state.clients = action.payload

      })
      .addCase(getSpecialClient.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
  }

})
export default ClientsSlice.reducer