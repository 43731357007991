import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASEURL } from "../../api/Api";

export const getAllPartners = createAsyncThunk('getAllPartners',
  async (data, rejectWithValue) => {
    try {

      const response = await axios.get(`${BASEURL}/website/company_partners/`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  })

export const getSpecialPartner = createAsyncThunk('getSpecialPartner',
  async (id, rejectWithValue) => {
    try {

      const response = await axios.get(`${BASEURL}/website/company_partners/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  })

const PartnerSlice = createSlice({
  name: 'Partners',
  initialState: {
    loading: false,
    error: null,
    partners: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllPartners.pending, (state, action) => {
        state.loading = true;
        state.error = null
        state.partners = []
      })
      .addCase(getAllPartners.fulfilled, (state, action) => {
        state.loading = false
        state.partners = action.payload

      })
      .addCase(getAllPartners.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
    builder
      .addCase(getSpecialPartner.pending, (state, action) => {
        state.loading = true;
        state.error = null
        state.partners = []
      })
      .addCase(getSpecialPartner.fulfilled, (state, action) => {
        state.loading = false
        state.partners = action.payload

      })
      .addCase(getSpecialPartner.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
  }

})
export default PartnerSlice.reducer