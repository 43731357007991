/** @jsxImportSource @emotion/react */
import React from "react";
import { A11y, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Link } from "react-router-dom";
import hero1 from "../../images/slider/b1.jpg";
import hero2 from "../../images/slider/b2.jpg";
import { css } from "@emotion/react";
// import hero3 from '../../images/slider/slide-5.jpg'
const slideInnerStyle = css`
  &::before {
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
`;
const Hero4 = () => {
  return (
    <section className="wpo-hero-slider wpo-hero-style-4">
      <Swiper
        // install Swiper modules
        modules={[Pagination, A11y]}
        spaceBetween={0}
        slidesPerView={1}
        loop={true}
        speed={1000}
        parallax={true}
        pagination={{ clickable: true }}
      >
        <SwiperSlide>
          <div
            css={slideInnerStyle}
            className="slide-inner slide-bg-image"
            style={{ backgroundImage: `url(${hero1})` }}
          >
            {/* <div className="container-fluid">
              <div className="slide-content" style={{ maxWidth: "none" }}>
                <div
                  data-swiper-parallax="300"
                  className="slide-title"
                  style={{ maxWidth: "none" }}
                >
                  <h2 style={{ textAlign: "center" }}>
                    مؤسسة الجمل للمحاماة والاستشارات القانونية
                  </h2>
                  <p style={{ fontSize: "2rem" }}> خلية خبراء القانون</p>
                </div>
                <div data-swiper-parallax="400" className="slide-text">
                  <p></p>
                </div>
                <div className="clearfix"></div>
                <div data-swiper-parallax="500" className="slide-btns">
                  <Link to="/practice" className="theme-btn-s2">
                    عرض خدماتنا{" "}
                  </Link>
                </div>
              </div>
            </div> */}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="slide-inner slide-bg-image"
            style={{ backgroundImage: `url(${hero2})` }}
          >
            <div className="container-fluid">
              <div className="slide-content">
                <div
                  data-swiper-parallax="300"
                  className="slide-title text-align-center"
                >
                  <h2 className="text-align-center">خدمات قانونية مختلفة</h2>
                </div>
                <div
                  data-swiper-parallax="400"
                  className="slide-text text-align-center"
                >
                  <p>
                    نوفر من خلال مؤسسة الجمل مجموعة مختلفة من الخدمات القانونية
                    التى تحتاج اليها
                  </p>
                </div>
                <div className="clearfix"></div>
                <div data-swiper-parallax="500" className="slide-btns">
                  <Link to="/practice" className="theme-btn-s2">
                    عرض خدماتنا
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        {/* <SwiperSlide>
                    <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero3})` }}>
                        <div className="container-fluid">
                            <div className="slide-content">
                                <div data-swiper-parallax="300" className="slide-title">
                                    <h2>The lawyer you choose will make a difference.</h2>
                                </div>
                                <div data-swiper-parallax="400" className="slide-text">
                                    <p>Bibendum commodo nulla id amet magna sit malesada. Et sceleque scelerisque
                                        mauris malesuada dui.</p>
                                </div>
                                <div className="clearfix"></div>
                                <div data-swiper-parallax="500" className="slide-btns">
                                    <Link to="/about" className="theme-btn-s2">Discover More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide> */}
        ...
      </Swiper>
    </section>
  );
};

export default Hero4;
