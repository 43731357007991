import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { BASEURL } from "../../api/Api";

const FunFact = (props) => {
  const [data, setData] = useState([]);
  const funFact = [
    {
      title: "235",
      subTitle: "Trusted Clients",
      Symbol: "+",
    },
    {
      title: "95",
      subTitle: "Cases Won",
      Symbol: "%",
    },
    {
      title: "120",
      subTitle: "Dedicated Lawyer",
      Symbol: "+",
    },
    {
      title: "15",
      subTitle: "Case Dismissed",
      Symbol: "%",
    },
  ];

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await fetch(`${BASEURL}/website/company_statistics/`);
        const body = await res.json();
        setData(body);
      } catch (e) {
        console.log("Hi");
        console.log(e);
      }
    };
    getData();
  }, []);
  return (
    <section className={`wpo-fun-fact-section content ${props.fnClass}`}>
      <div className="container">
        <div className="row">
          <div className="col col-xs-12">
            <div className="wpo-fun-fact-grids-wrap">
              <div className="wpo-fun-fact-grids clearfix">
                {data?.map((funfact, fitem) => (
                  <div className="grid align-baseline" key={fitem}>
                    <div className="info ">
                      <p style={{ fontSize: "2rem", margin: "0px" }}>
                        {funfact.title}
                      </p>

                      <h3 style={{ color: "#af914e ! important" }}>
                        <span className="" style={{ fontSize: "3.5rem" }}>
                          {funfact.percentage ? " %" : " +"}
                        </span>
                        <span style={{ fontSize: "3.5rem" }}>
                          {
                            <CountUp
                              end={funfact.number}
                              formattingFn={convertToArabicNumerals}
                              enableScrollSpy
                            />
                          }
                        </span>
                      </h3>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <span id="counter" className="d-none" />
    </section>
  );
};

export default FunFact;
function convertToArabicNumerals(englishNumber) {
  const englishToArabicMap = {
    0: "٠",
    1: "١",
    2: "٢",
    3: "٣",
    4: "٤",
    5: "٥",
    6: "٦",
    7: "٧",
    8: "٨",
    9: "٩",
  };
  console.log(englishNumber);
  return englishNumber
    .toString()
    .split("")
    .map((digit) => englishToArabicMap[digit] || digit)
    .join("");
}
