import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASEURL } from "../../api/Api";

export const getAllMaterials = createAsyncThunk('getAllMaterials',
  async (data, rejectWithValue) => {
    try {
      const response = await axios.get(`${BASEURL}/website/material/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  })

export const getSpecialMaterial = createAsyncThunk('getSpecialMaterial',
  async (id, rejectWithValue) => {
    try {
      const response = await axios.get(`${BASEURL}/website/material/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  })

const MaterialsSlice = createSlice({
  name: 'materials',
  initialState: {
    loading: false,
    error: null,
    materials: [],
    material: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllMaterials.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.materials = [];
      })
      .addCase(getAllMaterials.fulfilled, (state, action) => {
        state.loading = false;
        state.materials = action.payload;
      })
      .addCase(getAllMaterials.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(getSpecialMaterial.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.material = {};
      })
      .addCase(getSpecialMaterial.fulfilled, (state, action) => {
        state.loading = false;
        state.material = action.payload;
      })
      .addCase(getSpecialMaterial.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default MaterialsSlice.reducer;
