import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const HeaderTopbar = () => {
  const token = localStorage.getItem("token");
  const hundleLogOut = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };
  return (
    <div className="topbar">
      <div className="container-fluid ">
        <div className="row align-items-center justify-content-center ">
          <div className="col col-lg-7 col-sm-12 col-12">
            <div className="contact-intro">
              {/* <ul className="text-center p-0 justify-content-sm-center justify-content-around">
                <li>
                  02/38659337<i className="fi flaticon-009-telephone"></i>
                </li>
                <li>
                  {" "}
                  <a href="mailto:info@algamallawfirm.com">
                    info@algamallawfirm.com
                  </a>
                  <i className="fi flaticon-010-email"></i>
                </li>
                <li>
                  المواعيد: من 12 ظهرًا حتى 6 مساءً
                  <i className="fa-regular fa-clock"></i>{" "}
                </li>
              </ul> */}
            </div>
          </div>
          <div className="col col-lg-5 col-sm-12 col-12 m">
            <div className="contact-info justify-content-center align-items-center">
              <ul className="p-0">
                <li>
                  <Link
                    to="https://www.facebook.com/profile.php?id=100063945067534"
                    className="social"
                  >
                    {" "}
                    <i className="ti-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://twitter.com/Algamallawfirm"
                    className="social"
                  >
                    <i className="ti-twitter-alt"></i>
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="https://www.instagram.com/Ehkam.sa/"
                    className="social"
                  >
                    <i className="ti-instagram"></i>
                  </Link>
                </li> */}
                <li>
                  <Link
                    to="https://www.linkedin.com/company/algamal-consultancy/posts/?feedView=all"
                    className="social"
                  >
                    <i class="fa-brands fa-linkedin"></i>
                  </Link>
                </li>
              </ul>
              <div className="contact-intro align-items-center">
                <ul className="d-flex gap-3  p-0">
                  <li>
                    <Link
                      to="https://elgamal-dashboard-ig982.ondigitalocean.app/signin/"
                      target="_blank"
                      className="social"
                    >
                      {" "}
                      بوابة الموظفين
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://algammal-client-8ffbl.ondigitalocean.app/signin"
                      target="_blank"
                      className="social"
                    >
                      {" "}
                      بوابة العملاء
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      to="https://ehkaam.com.sa/Fares-Ramadan/authentication/login"
                      target="_blank"
                      className="social"
                    >
                      {" "}
                      بوابة إحكام
                    </Link>
                  </li> */}
                </ul>
                <li
                  onClick={hundleLogOut}
                  className={`border-none background-transparent  mt-1 ${
                    token ? "d-flex" : "d-none"
                  }`}
                  style={{ paddingTop: "12px", paddingRight: "10px" }}
                >
                  <i className="fa-solid fa-power-off"></i>
                </li>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTopbar;
