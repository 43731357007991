import React from "react";
import ContactForm from "../ContactFrom";

const Contactpage = ({ children, title = "حجز موعد" }) => {
  return (
    <section className="wpo-contact-pg-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col col-lg-12 offset-lg-1">
            <div className=" wpo-section-title">
              <h2>{title}</h2>
              <p>راسلنا الأن وسنقوم بالرد عليك فى أسرع وقت</p>
            </div>
            <div className="wpo-practice-section-s2">
              {children}
              {/* <ContactForm /> */}
            </div>
            <div className="office-info">
              <div className="row">
                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <i className="fi flaticon-011-maps-and-flags"></i>
                      </div>
                    </div>
                    <div className="office-info-text">
                      <h2>العنوان</h2>
                      <p>
                        <a
                          target="_blank"
                          href="https://maps.app.goo.gl/hDJmnZJUACy1zvBK6"
                        >
                          فيلا 66 – المحور المركزي – الشيخ زايد
                        </a>
                      </p>
                      {/* <p>
                        الرياض - حي الياسمين – طريق انس بن مالك,المملكة العربية
                        السعوديه
                      </p> */}
                    </div>
                  </div>
                </div>
                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <i className="fi flaticon-037-email-1"></i>
                      </div>
                    </div>
                    <div className="office-info-text">
                      <h2>البريد الالكترونى</h2>
                      <p>
                        <a href="mailto:info@algamallawfirm.com">
                          info@algamallawfirm.com
                        </a>
                      </p>
                      <p>البريد الإلكتروني، وعادة ما نرد في غضون 24 ساعة.</p>
                    </div>
                  </div>
                </div>
                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <i className="fi flaticon-009-telephone"></i>
                      </div>
                    </div>
                    <div className="office-info-text">
                      <h2>اتصل بنا</h2>
                      <p> 02/38659337</p>
                      <p> من السبت إلى الأربعاء: 12 ظهرًا حتى 6 مساءً</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="wpo-contact-map-section">
        <div className="wpo-contact-map">
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6907.254149291042!2d30.976490000000002!3d30.047555000000003!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1458598fde9bc1ed%3A0x3e43ba8268350818!2z2YXYpNiz2LPYqSDYp9mE2KzZhdmEINmE2YTZhdit2KfZhdin2Kk!5e0!3m2!1sen!2seg!4v1727703942817!5m2!1sen!2seg"></iframe>
        </div>
      </section>
    </section>
  );
};

export default Contactpage;
