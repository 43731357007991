import React from 'react';
import book from "../../images/book.png";
import 'bootstrap/dist/css/bootstrap.min.css';
import img from  '../../images/about.jpg';
import{ useState, useEffect } from "react";
import axios from "axios";
import { Document , Page } from 'react-pdf';


// export default function BookCard() {
//   return (
//     <div className="card  " style={{  objectFit:"cover"  }}>
//       <img className="card-img-top" src={book} alt="Card image cap" />
//       <div className="card-body">
//         <h5 className="card-title">أصول القانون</h5>
//         <p className="card-text text-black">
//           كتاب يشرح المبادئ الأساسية للقانون ومصادره بأسلوب مبسط ومباشر.
//         </p>
//       </div>
//     </div>
//   );
// }





export default function BookCard() {
  const [details, setDetails] = useState([]);

  async function getDetails() {
    try {
      const response = await axios.get(
        `https://elgamal.cyparta.com/website/material/?format`
      );
      console.log("Fetched Books Data:", response.data);

      // Deduplicate data if necessary
      const uniqueBooks = Array.from(
        new Set(response.data.map((book) => book.id))
      ).map((id) => response.data.find((book) => book.id === id));

      setDetails(uniqueBooks);
    } catch (error) {
      console.error("Error fetching book details:", error);
      setDetails([]); // Fallback to empty array
    }
  }

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <div className="container">

   <div className="row d-flex flex-col ">
   {details && details.length > 0 ? (
        
        details.map((book) => (
          <div className='col-md-3 col-6 m-[5px] '> 
            <div
            key={book.id}
            className="card my-4 "
            style={{ objectFit: "cover", padding: "10px" ,maxHeight: "300px" , overflow: "hidden" }}>
             {/* <a href={book.file} target="_blank"
                    rel="noreferrer">
                    <img
              className="card-img-top"
              src={img}
              alt={book.title}
              style={{ height: "200px", objectFit: "contain" }}
            />
                </a> */}
             
             <div
  className="card-img-top"
  style={{
    height: '200px',
    overflow: 'hidden',
    textAlign: 'center',
    position: 'relative',
    cursor: 'pointer', // Add cursor to indicate clickability
  }}
  onClick={() => {
    window.open(book.file, '_blank', 'noopener,noreferrer');
  }}
>
  <object
    data={book.file}
    type="application/pdf"
    style={{
      height: '100%',
      width: '100%',
      pointerEvents: 'none',
    }}
  ></object>
</div>

            <div className="card-body">
              <h5 className="card-title">{book.title}</h5>
              <p
                className="card-text text-black"
                dangerouslySetInnerHTML={{ __html: book.description }}
              ></p>
            </div>
          </div>
        </div>
      ))
    ) : (
      <p>Loading books...</p>
    )}
  </div>
    </div>
  );
}



