import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Contactpage from "../../components/Contactpage/Contactpage";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import ContactFormApplicant from "../../components/ContactFrom/indexApplicant";
function ContactApplicant() {
  return (
    <Fragment>
      <Navbar hclass={"wpo-header-style-5"} topbarClass={"tb-block"} />
      <PageTitle
        pageTitle={" الإنضمام لفريق المؤسسة"}
        pagesub={"الإنضمام لفريق المؤسسة"}
      />
      <Contactpage title="إنضم إلينا">
        <ContactFormApplicant />
      </Contactpage>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
}

export default ContactApplicant;
