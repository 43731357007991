import axios from "axios";
import React, { useEffect, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { BASEURL } from "../../api/Api";
import { toast } from "react-toastify";

const ContactFormCompanies = () => {
  const initialFormState = {
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  };

  const formFields = [
    {
      name: "name",
      placeholder: "الإسم",
      type: "text",
      validation: "required|alpha_space",
    },
    {
      name: "email",
      placeholder: "البريد الإلكتروني",
      type: "email",
      validation: "required|email",
    },
    {
      name: "phone",
      placeholder: "الهاتف المحمول",
      type: "text",
      validation: "required|phone",
    },
    {
      name: "company_name",
      placeholder: "إسم الشركة",
      type: "text",
      validation: "required|alpha_space",
    },
  ];

  const [forms, setForms] = useState(initialFormState);
  const [services, setServices] = useState([]);
  const [validator] = useState(
    new SimpleReactValidator({ className: "errorMessage" })
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForms((prevState) => ({ ...prevState, [name]: value }));

    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      validator.hideMessages();
      setForms(initialFormState); // Reset form to initial state
    } else {
      validator.showMessages();
    }
  };
  useEffect(() => {
    const getServices = async () => {
      try {
        const response = await axios.get(`${BASEURL}/website/service/`);
        setServices(response.data);
        return response.data;
      } catch (error) {
        setServices([]);
        return [];
      }
    };
    getServices();
  }, []);
  const sendReservation = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${BASEURL}/user/company-appointment/`,
        forms
      );
      if (response.status == 201) {
        toast.success("تم إرسال طلبك بنجاح");
        setForms(initialFormState); // Reset form on successful submission
      }
    } catch (error) {
      console.error("Error sending reservation:", error.response);
      if (error?.response?.status == 400) {
        Object.keys(error?.response.data).map((key) => {
          toast.warning(
            `${key}: ${
              Array.isArray(error?.response.data[key])
                ? error?.response.data[key][0]
                : error?.response.data[key]
            }`
          );
        });
      }
    }
  };
  return (
    <form onSubmit={handleSubmit} className="contact-validation-active">
      <div className="row">
        {formFields.map((field, index) => {
          return (
            <div key={index} className="col col-lg-6 col-12">
              <div className="form-field">
                <input
                  value={forms[field.name]}
                  type={field.type}
                  name={field.name}
                  onBlur={handleInputChange}
                  onChange={handleInputChange}
                  placeholder={field.placeholder}
                />
                {validator.message(
                  field.name,
                  forms[field.name],
                  field.validation
                )}
              </div>
            </div>
          );
        })}
        <div className="col col-lg-6 col-12">
          <div className="form-field">
            <select
              style={{ maxHeight: "50vh" }}
              name="service"
              value={forms.service}
              onBlur={handleInputChange}
              onChange={handleInputChange}
            >
              <option value="" disabled>
                نوع الخدمة
              </option>
              {services.map((option, index) => (
                <option key={index} value={option.id}>
                  {option.title}
                </option>
              ))}
            </select>
            {validator.message("service", forms.service, "required")}
          </div>
        </div>
        <div className="col col-lg-12 col-12">
          <div className="form-field">
            <textarea
              value={forms.message}
              name="message"
              onBlur={handleInputChange}
              onChange={handleInputChange}
              placeholder="الموضوع"
            />
            {/* {validator.message("message", forms.message, "required")} */}
          </div>
        </div>
      </div>
      <div className="submit-area">
        <button onClick={sendReservation} type="submit" className="theme-btn">
          إرسال
        </button>
      </div>
    </form>
  );
};

export default ContactFormCompanies;
