import axios from "axios";
import React, { useEffect, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { BASEURL } from "../../api/Api";
import { toast } from "react-toastify";

const ContactFormApplicant = () => {
  const initialFormState = {
    name: "",
    type: "",
    email: "",
    phone: "",
    cv: null,
    birthday: "",
    message: "",
  };
  const formFields = [
    {
      name: "name",
      placeholder: "الإسم",
      type: "text",
      validation: "required|alpha_space",
    },
    {
      name: "job_title",
      placeholder: "المسمى الوظيفي",
      type: "text",
      validation: "required|alpha_space",
    },
    {
      name: "email",
      placeholder: "البريد الإلكتروني",
      type: "email",
      validation: "required|email",
    },
    {
      name: "phone",
      placeholder: "الهاتف المحمول 20+",
      type: "text",
      validation: "required|phone",
    },
    {
      name: "cv",
      placeholder: "السيرة الذاتية",
      type: "file",
      validation: "required|file",
    },
    {
      name: "birthday",
      placeholder: "تاريخ الميلاد",
      type: "date",
      validation: "",
    },
  ];

  const [forms, setForms] = useState(initialFormState);

  const [validator] = useState(
    new SimpleReactValidator({ className: "errorMessage" })
  );

  const handleInputChange = (e) => {
    const { name, value, files, type } = e.target;
    console.log(files);
    if (type == "file") {
      setForms((prevState) => ({
        ...prevState,
        [name]: files[0],
      }));
    } else {
      setForms((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
  };

  const sendReservation = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    console.log(forms);
    Object.keys(forms).forEach((key) => {
      formData.append(key, forms[key]);
    });

    try {
      const response = await axios.post(`${BASEURL}/user/career/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 201) {
        toast.success("تم إرسال طلبك بنجاح");
        setForms(initialFormState);
      }
    } catch (error) {
      console.error("Error sending reservation:", error.response);
      if (error?.response?.status === 400) {
        Object.keys(error?.response.data).forEach((key) => {
          toast.warning(
            `${key}: ${
              Array.isArray(error?.response.data[key])
                ? error?.response.data[key][0]
                : error?.response.data[key]
            }`
          );
        });
      }
    }
  };
  console.log(forms);
  return (
    <form onSubmit={sendReservation} className="contact-validation-active">
      <div className="row">
        {formFields.map((field, index) => {
          return (
            <div key={index} className="col col-lg-6 col-12">
              <div className="form-field">
                <p>{field.placeholder}</p>
                <input
                  style={{ color: "gray", padding: "0px 1rem" }}
                  value={field.type !== "file" ? forms[field.name] : ""}
                  type={field.type}
                  name={field.name}
                  // onBlur={handleInputChange}
                  onChange={handleInputChange}
                  placeholder={field.placeholder}
                />
                {validator.message(
                  field.name,
                  forms[field.name],
                  field.validation
                )}
              </div>
            </div>
          );
        })}

        <div className="col col-lg-12 col-12">
          <div className="form-field">
            <textarea
              value={forms.message}
              name="message"
              onBlur={handleInputChange}
              onChange={handleInputChange}
              placeholder="الموضوع"
            />
            {/* {validator.message("message", forms.message, "required")} */}
          </div>
        </div>
      </div>
      <div className="submit-area">
        <button type="submit" className="theme-btn">
          إرسال
        </button>
      </div>
    </form>
  );
};

export default ContactFormApplicant;
