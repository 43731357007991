import React, { Fragment, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { useLocation, useParams } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getAllMaterials } from "../../store/pages/materialSlice";
import BookCard from "./BookCard";

const Materials = (props) => {
  const { title } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { materials, loading } = useSelector((state) => state.material);
  console.log(materials);

  useEffect(() => {
    dispatch(getAllMaterials());
    window.scrollTo(10, 0);
  }, [dispatch]);

  return (
    <Fragment>
      <Navbar hclass={"wpo-header-style-5"} topbarClass={"tb-block"} />
      <PageTitle pageTitle={"معرفة قانونية"} pagesub={"معرفة قانونية "} />
      <div className="container-fluid">
  {/* <div  className="row d-flex flex-col"> */}
    {/* BookCard 1 */}
    {/* <div  className="col-md-3 col-6 mt-4 ml-md-0 mr-md-3"> */}
  
     <BookCard />

    {/* </div> */}
    
    {/* BookCard 2
    <div className="col-md-3 col-6 mt-4 mr-md-3">
      <BookCard />
    </div>
    
    {/* BookCard 3 */}
    {/* <div className="col-md-3 col-6 mt-4 mr-md-3">
      <BookCard />
    </div> */}
    
    {/* BookCard 4 */}
    {/* <div className="col-md-3 col-6 mt-4 mr-md-3">
      <BookCard />
    </div> */}
    
    {/* BookCard 5 */}
    {/* <div className="col-md-3 col-6 mt-4 ml-md-3">
      <BookCard />
    // </div> */}
    {/* // <div className="col-md-3 col-6 mt-4 ml-md-3">
    //   <BookCard />
    // </div> */}
    {/* <div className="col-md-3 col-6 mt-4 ml-md-3">
      <BookCard />
    </div> */}
    {/* <div className="col-md-3 col-6 mt-4 ml-md-3">
      <BookCard />
    </div> */} 
  {/* </div> */}
</div>


      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default Materials;
