import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../images/logo (1).png";
import Practices from "../../api/Practices";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllServices,
  getSpecialService,
} from "../../store/pages/servicesSlice";

const Footer = (props) => {
  const services = useSelector((state) => state.services);
  const navigate = useNavigate();
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  const ClickHandlerApi = (practice_id) => {
    dispatch(getSpecialService(practice_id));
    // window.scrollTo(10, 0);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (!services) {
      dispatch(getAllServices());
    }
  }, [dispatch]);
  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  return (
    <footer className="wpo-site-footer">
      <div className="wpo-upper-footer">
        <div className="container">
          <div className="row align-items-center">
            <div className=" col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget fw-bold">
                <div className="logo widget-title pt-2 text-center ">
                  <img src={Logo} alt="blog" />
                </div>
                <p style={{ textAlign: "center" }}>
                  {" "}
                  لدعمك القانوني وحماية مصالحك
                </p>
                <div className="about-widget text-center">
                  <ul
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      listStyle: "none",
                    }}
                  >
                    <li>
                      <Link to="https://www.facebook.com/Algamallawfirm.eg/">
                        {" "}
                        <i className="ti-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="https://x.com/Algamallawfirm">
                        <i className="ti-twitter-alt"></i>
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="https://www.instagram.com/Ehkam.sa/">
                        <i className="ti-instagram"></i>
                      </Link>
                    </li> */}
                    <li>
                      <Link to="https://www.linkedin.com/in/algamal-consultancy-088924252/">
                        <i className="fa-brands fa-linkedin"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className=" col-lg-3 offset-lg-1 col-md-6 col-6  text-end">
              <div className="widget link-widget">
                <div className="widget-title ">
                  <h3>الصفحات</h3>
                </div>
                <ul className="list-style-circle p-0">
                  <li>
                    <Link onClick={ClickHandler} to="/home">
                      الرئيسية
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/about">
                      من نحن
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/practice">
                      خدمات المؤسسة{" "}
                    </Link>
                  </li>
                  {/* <li>
                    <Link onClick={ClickHandler} to="/shop">
                      {" "}
                      الخدمات الالكترونية{" "}
                    </Link>
                  </li> */}
                  <li>
                    <Link onClick={ClickHandler} to="/profile">
                      {" "}
                      الملف التعريفى{" "}
                    </Link>
                  </li>
                  {/* <li>
                    <Link onClick={ClickHandler} to="/recommendwebsite">
                      {" "}
                      مواقع تهمك{" "}
                    </Link>
                  </li> */}
                  <li>
                    <Link onClick={ClickHandler} to="/attorneys">
                      فريق المؤسسة
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/Contact">
                      اتصل بنا
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/articals">
                      {" "}
                      مقاﻻت قانونية
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className=" col-lg-4 col-md-6 col-6  text-end">
              <div className="widget link-widget s2">
                <div className="widget-title text-end">
                  <h3>الخدمات</h3>
                </div>
                {services?.loading ? (
                  <p>Loading...</p>
                ) : (
                  <ul className="list-style-circle p-0">
                    {Array.isArray(services.services) &&
                      services.services.slice(0, 6).map((practice, Pitem) => (
                        <li
                          key={Pitem}
                          onClick={() => {
                            window.scrollTo(10, 0);
                            ClickHandlerApi(practice?.id);
                          }}
                        >
                          <Link to={`/practice-single/${practice?.id}`}>
                            {practice?.title}
                          </Link>
                        </li>
                      ))}
                    <li>
                      <Link to={`/practice/`}>المزيد ....</Link>
                    </li>
                  </ul>
                )}
              </div>
            </div>
            {/* <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget newsletter-widget">
                                <div className="widget-title">
                                    <h3>متابعة اخر الاخبار</h3>
                                </div>
                                <form onSubmit={SubmitHandler}>
                                    <div className="input-1">
                                        <input type="email" className="form-control" placeholder="ارسل عنوان البريد اﻻلكترونى..."
                                            required="" />
                                    </div>
                                    <div className="submit clearfix">
                                        <button type="submit">متابعة</button>
                                    </div>
                                </form>
                            </div>
                        </div> */}
          </div>
        </div>
      </div>
      <div className="wpo-lower-footer">
        <div className="container">
          <div className="row align-items-center">
            <div className="col col-lg-6 col-md-12 col-12">
              <ul className="">
                <li>
                  حقوق النشر &copy; 2024
                  <Link onClick={ClickHandler} to="https://cyparta.com/">
                    سايبرتا
                  </Link>
                  . كل الحقوق محفوظة.
                </li>
              </ul>
            </div>
            {/* <div className="col col-lg-6 col-md-12 col-12">
              <div className="link">
                <ul className="list-style-circle p-0">
                  <li>
                    <Link onClick={ClickHandler} to="/privacy">
                      Privace & Policy
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/terms">
                      Terms
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/about">
                      About us
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/faq">
                      FAQ
                    </Link>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
