import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAllServices, getSpecialService } from "../../store/pages/servicesSlice";
import { useDispatch, useSelector } from "react-redux";

const PracticeMain = (props) => {
  const { services, loading } = useSelector((state) => state.services);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  useEffect(() => {
    dispatch(getAllServices());
  }, [dispatch]);

  return (
    <section className={`wpo-practice-section-s2 ${props.prClass}`}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-8">
            <div className="wpo-section-title">
              <h2 style={{ color: "rgb(175, 145, 78)" }}>خدمات المؤسسة</h2>
              <p>
                استخدام المعرفة القانونية في تقديم الخدمات القانونية المثلى
                لعملائنا بما يضمن مصالحهم ونجاح أعمالهم التجارية وقضاياهم.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="wpo-practice-wrap">
          {/* Flexbox Container */}
          <div className="wpo-practice-items" style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
            {loading ? (
              <div
                className="spinner-border"
                style={{ width: "3rem", height: "3rem" }}
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : services?.length > 0 ? (
              services?.map((practice) => (
                <div className="wpo-practice-item" key={practice.id} style={{ flex: "1 1 calc(20% - 20px)", margin: "10px", minWidth: "250px" }}>
                  <div className="wpo-practice-img">
                    <img
                      src={practice.image}
                      alt={practice.title}
                      style={{ height: "550px", width: "100%", objectFit: "cover" }}
                    />
                  </div>
                  <div className="wpo-practice-content">
                    <div className="wpo-practice-content-top">
                      <div className="b-top">
                        <div className="b-top-inner">
                          <h2
                            onClick={() =>
                              navigate(`/practice-single/${practice?.title}`, {
                                state: { practice_id: practice.id },
                              })
                            }
                          >
                            <Link style={{ fontSize: "2rem" }}>
                              {practice?.title}
                            </Link>
                          </h2>
                          <p></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No services available.</p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PracticeMain;
